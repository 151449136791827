import React, { useState, useEffect } from 'react';
import './index.css';
import { Button, Input, Layout, List, Menu, Modal, message, Popconfirm, Segmented, Select, Space, Switch, Table, Tag, theme, Tooltip, Upload } from 'antd';
import copy from 'copy-to-clipboard';
import { AppstoreOutlined, CheckOutlined, ClearOutlined, CopyTwoTone, DeleteOutlined, ExclamationOutlined, MonitorOutlined, PushpinOutlined, ReloadOutlined, SearchOutlined, StopOutlined, UploadOutlined, WarningOutlined } from '@ant-design/icons';

import axios from 'axios';

const { Option } = Select;
const { Header, Content, Footer } = Layout;

type Whois = {
  id: number;
  domain: string;
  regTime: string;
  endTime: string;
  updated: string;
  registrar: string;
  status: string;
};

interface Result {
  name: string;
  availability: string;
}

const App: React.FC = () => {
  const [tabKey, setTabKey] = useState<string>('1');
  const [prefixes, setPrefixes] = useState<string[]>([]);
  const [suffixes, setSuffixes] = useState<string[]>([]);
  const [includeRegistered, setIncludeRegistered] = useState(false);
  const [checkResults, setCheckResults] = useState<Result[]>([]);
  const [segmentedValue, setSegmentedValue] = useState<string | number>('DA-full');
  const [loading, setLoading] = useState(false);
  const [whoisArr, setWhoisArr] = useState<Whois[]>([]);
  const [searchPrompt, setSearchPrompt] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const hasSelected = selectedRowKeys.length > 0;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // Table columns
  const columns = [
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sorter: (a: Whois, b: Whois) => a.domain.localeCompare(b.domain),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Registration Time",
      dataIndex: "regTime",
      key: "regTime",
      sorter: (a, b) => a.regTime.localeCompare(b.regTime),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      sorter: (a, b) => a.endTime.localeCompare(b.endTime),
    },
    {
      title: "Updated Time",
      dataIndex: "updated",
      key: "updated",
      sorter: (a, b) => a.updated.localeCompare(b.updated),
    },
    Table.EXPAND_COLUMN,
    {
      title: "Registrar",
      dataIndex: "registrar",
      key: "registrar",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      render: (_: string, record: Whois) => (
        <Space size='middle'>
          <Button
            shape='circle'
            icon={<ReloadOutlined />}
            onClick={() => onUpdate(record.domain)}
          />
          <Popconfirm
            icon={<WarningOutlined />}
            title='Sure to delete?'
            onConfirm={() => onDelete(record.domain)} okText="Yes" cancelText="Cancel">
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Fetch whois data
  useEffect(() => {
    fetchWhois();
  }, []);

  const fetchWhois = async (prompt = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `http://bamboo.cc/whois${prompt.length === 0 ? "" : "?prompt=" + prompt}`
      );
      setWhoisArr(res.data.whoisArr);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchWhois(searchPrompt.trim());
  };

  // Delete domain record
  const onDelete = async (domain: string) => {
    await axios.delete(`http://bamboo.cc/whois/remove/${domain}`);
    setWhoisArr(whoisArr.filter((rec) => rec.domain !== domain));
  };

  // Update domain record
  const onUpdate = async (domain: string) => {
    try {
      const url = `http://bamboo.cc/whois/update/${domain}`;
      const response = await axios.patch(url);
      const updatedData = whoisArr.map((item) => {
        if (item.domain === domain) {
          return response.data;
        }
        return item;
      });
      message.success('更新成功');
      setWhoisArr(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  // Batch delete selected records
  const onBatchDelete = () => {
    Modal.confirm({
      title: "Confirm Delete",
      content: `Sure to delete the selected ${selectedRowKeys.length} domains?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          const toDelete = whoisArr.filter((rec) =>
            selectedRowKeys.includes(rec.domain)
          );
          const domains = toDelete.map((rec) => rec.domain);
          const ids = toDelete.map((rec) => rec.id);
          await axios.delete('http://bamboo.cc/whois/rmcolumn', { data: { domain: domains, id: ids } });
          message.success('删除成功');
          setWhoisArr(whoisArr.filter((rec) => !domains.includes(rec.domain)));
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  // Batch update selected records
  const onBatchUpdate = async () => {
    try {
      const toUpdate = whoisArr.filter((rec) => selectedRowKeys.includes(rec.domain));
      const res = await axios.put('http://bamboo.cc/whois/update', {
        domains: toUpdate.map((rec) => ({ id: rec.id, name: rec.domain })),
      });
      const newData = [...whoisArr];
      res.data.whoisArr.forEach((updatedRec: Whois) => {
        const index = newData.findIndex(
          (rec) => rec.domain === updatedRec.domain
        );
        newData[index] = updatedRec;
      });
      message.success('更新成功');
      setWhoisArr(newData.sort((a, b) => a.id - b.id));
    } catch (error) {
      console.error(error);
    }
  };

  // Table row selection settings
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: React.Key[]) => setSelectedRowKeys(keys as string[]),
  };

  const handleUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result as string;
      const tags = content.split(/[,;:/、\n，；：+\s|\\]+/).map(tag => tag.trim()).filter(tag => tag !== '');
      const newTags = tags.filter((tag, index, self) => self.indexOf(tag) === index && !prefixes.includes(tag));
      if (newTags.length > 0) {
        setPrefixes([...prefixes, ...newTags]);
        message.success('文件上传成功！');
      } else {
        message.warning('没有新标签添加到列表中！');
      }
    };
    reader.readAsText(file);
  };

  const onCheck = async () => {
    const apiUrl =
      segmentedValue === 'DA-full' || segmentedValue === 'DA-join'
        ? 'http://bamboo.cc/available'
        : 'http://bamboo.cc/whois';
    const postData = {
      prefixes: prefixes,
      suffixes: suffixes,
      ...(includeRegistered && (segmentedValue === 'DA-full' || segmentedValue === 'DA-join') && { include_registered: true }),
    };

    try {
      if (segmentedValue === 'Whois-join' || segmentedValue === 'Whois-full') {
        setLoading(true);
        setTabKey('2');
      }
      const response = await axios.post(apiUrl, postData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (segmentedValue === 'DA-full' || segmentedValue === 'DA-join') {
        setCheckResults(response.data.results);
      } else {
        setWhoisArr(response.data.whoisArr);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onExport = () => {
    let clipboardData = '';
    checkResults.forEach((result) => {
      clipboardData += `${result.name},${result.availability}\n`;
    });
    copy(clipboardData);
  };

  return (
    <Layout>
      <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
        <div
          style={{
            float: 'left',
            width: 120,
            height: 31,
            margin: '16px 24px 16px 0',
            background: 'rgba(255, 255, 255, 0.2)',
          }} >
        </div>
        <img src='/images/logo.png' alt='Logo' style={{ float: 'right', width: 'auto', height: '64px' }} />
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={(e) => {
            console.log('click ', e);
            setTabKey(e.key);
          }}
          defaultSelectedKeys={['1']}
          selectedKeys={[tabKey]}
          items={tabPage}
        />
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px' }}>
        <div style={{ minHeight: 30, background: colorBgContainer }}>
          {tabKey === '1' && (
            <Space direction='vertical' style={{ padding: 28, display: 'flex', justifyContent: 'space-between' }}>
              <Space size='middle' align='center' wrap>
                <Segmented
                  options={['DA-join', 'DA-full', 'Whois-join', 'Whois-full']}
                  value={segmentedValue}
                  onChange={(value) => {
                    setSegmentedValue(value);
                    setPrefixes([]);
                    setSuffixes([]);
                  }} />
                <Switch
                  checkedChildren="全列出"
                  unCheckedChildren="仅可用"
                  disabled={segmentedValue === 'Whois-full' || segmentedValue === 'Whois-join'}
                  onChange={() => setIncludeRegistered(!includeRegistered)} />
                <Upload
                  accept=".txt"
                  showUploadList={false}
                  beforeUpload={handleUpload}
                >
                  <Button icon={<UploadOutlined />}>上传</Button>
                </Upload>

                <Button
                  type="primary"
                  shape="round"
                  icon={<SearchOutlined />}
                  onClick={onCheck}>Check</Button>
                <Button
                  type="text"
                  shape="round"
                  icon=<CopyTwoTone />
                  disabled={checkResults.length === 0}
                  onClick={onExport}>
                  Export to Clipboard
                </Button>
              </Space>

              <Select
                open={false}
                allowClear
                placeholder={segmentedValue === 'DA-full' || segmentedValue === 'Whois-full' ? "在此输入完整域名" : "在此输入域名前缀"}
                mode="tags"
                size="large"
                // mode="multiple"
                autoFocus
                showSearch
                clearIcon={<ClearOutlined />}
                menuItemSelectedIcon={<PushpinOutlined />}
                defaultOpen={false}
                style={{ width: '100%' }}
                value={prefixes}
                onChange={setPrefixes}
                tokenSeparators={[',', ';', ':', '/', '、', '，', '；', '：', '+', '|', '\\', ' ']}
                maxTagTextLength={16} />
              <Select
                allowClear
                placeholder="在此选择域名后缀"
                mode="multiple"
                showSearch
                disabled={segmentedValue === 'DA-full' || segmentedValue === 'Whois-full'}
                menuItemSelectedIcon={<PushpinOutlined />}
                defaultOpen={false}
                style={{ width: '100%' }}
                value={suffixes}
                onChange={setSuffixes}
                tokenSeparators={[',', ';', ':', '/', '、', '，', '；', '：', '+', '|', '\\', ' ']}
                maxTagTextLength={9}
                options={segmentedValue === 'DA-join' ? availSuffix : whoisSuffix} />
              <List
                itemLayout="horizontal"
                size="small"
                split={true}
                dataSource={checkResults}
                renderItem={(item: any) => (
                  <List.Item>
                    <Tag color={item.availability === 'available' ? 'success' : (item.availability === 'registered' ? 'error' : 'warning')}>
                      {item.name}
                    </Tag>
                    {item.availability === 'available'
                      ? (<CheckOutlined style={{ color: 'green' }} />)
                      : (item.availability === 'registered'
                        ? (<StopOutlined style={{ color: 'red' }} />)
                        : (<ExclamationOutlined style={{ color: 'gold' }} />))
                    }
                  </List.Item>
                )}
              />
            </Space>
          )}
          {tabKey === '2' && (
            <Table<Whois>
              size='middle'
              rowKey='domain'
              style={{ height: "100%", width: "100%" }}
              rowSelection={rowSelection}
              scroll={{ y: window.innerHeight * 0.73 }}
              columns={columns}
              dataSource={whoisArr}
              loading={loading}
              expandable={{
                expandedRowRender: (record) => (
                  <p style={{ margin: 0 }}>{record.status}</p>
                ),
                columnTitle: <div style={{ textAlign: 'center' }}>Status</div>,
                columnWidth: "8%",
              }}
              title={() => (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px'
                }}>

                  <Input.Search
                    placeholder='Prompt'
                    addonBefore="Matching"
                    maxLength={68}
                    value={searchPrompt}
                    onChange={(e) => setSearchPrompt(e.target.value.replace(/\s+/g, ''))}
                    style={{ width: '64%' }}
                    allowClear
                    onSearch={handleSearch}
                    enterButton
                  />
                  <Tooltip title="Batch Delete Behavior (Invocatable)">
                    <Button
                      type="text"
                      danger
                      disabled={!hasSelected}
                      onClick={onBatchDelete}>Delete Selected</Button>
                  </Tooltip>
                  <Button
                    type="primary"
                    disabled={!hasSelected}
                    onClick={onBatchUpdate}>Update Selected</Button>
                  <Select
                    defaultValue={pageSize}
                    style={{ width: 101 }}
                    onChange={(value) => setPageSize(value)}
                  >
                    <Option value={10}>10 / page</Option>
                    <Option value={20}>20 / page</Option>
                    <Option value={40}>40 / page</Option>
                  </Select>
                </div>
              )}
              pagination={{
                hideOnSinglePage: true,
                pageSize: pageSize,
                position: ['bottomCenter'],
                showQuickJumper: true,
                showSizeChanger: false,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                size: 'default',
              }}
            >
            </Table>
          )}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer>
    </Layout>
  );
};

export default App;

const tabPage = [
  {
    key: '1',
    label: '域名批量查询',
    icon: <MonitorOutlined />,
  },
  {
    key: '2',
    label: 'Whois管理面板',
    icon: <AppstoreOutlined />,
  },
]
// const whoisSuffix = [
//   {
//     value: '.asia',
//     label: 'asia',
//   },
//   {
//     value: '.biz',
//     label: 'biz',
//   },
//   {
//     value: '.cc',
//     label: 'cc',
//   },
//   {
//     value: '.club',
//     label: 'club',
//   },
//   {
//     value: '.cn',
//     label: 'cn',
//   },
//   {
//     value: '.com',
//     label: 'com',
//   },
//   {
//     value: '.com.cn',
//     label: 'com.cn',
//   },
//   {
//     value: '.info',
//     label: 'info',
//   },
//   {
//     value: '.net',
//     label: 'net',
//   },
//   {
//     value: '.net.cn',
//     label: 'net.cn',
//   },
//   {
//     value: '.org',
//     label: 'org',
//   },
//   {
//     value: '.org.cn',
//     label: 'org.cn',
//   },
//   {
//     value: '.shop',
//     label: 'shop',
//   },
//   {
//     value: '.top',
//     label: 'top',
//   },
//   {
//     value: '.vip',
//     label: 'vip',
//   },
//   {
//     value: '.wang',
//     label: 'wang',
//   },
// ];

const whoisSuffix = [
  {
    value: ".academy",
    label: "academy",
  },
  {
    value: ".agency",
    label: "agency",
  },
  {
    value: ".app",
    label: "app",
  },
  {
    value: ".art",
    label: "art",
  },
  {
    value: ".asia",
    label: "asia",
  },
  {
    value: ".beer",
    label: "beer",
  },
  {
    value: ".best",
    label: "best",
  },
  {
    value: ".bet",
    label: "bet",
  },
  {
    value: ".bid",
    label: "bid",
  },
  {
    value: ".bio",
    label: "bio",
  },
  {
    value: ".biz",
    label: "biz",
  },
  {
    value: ".black",
    label: "black",
  },
  {
    value: ".blue",
    label: "blue",
  },
  {
    value: ".boo",
    label: "boo",
  },
  {
    value: ".build",
    label: "build",
  },
  {
    value: ".business",
    label: "business",
  },
  {
    value: ".cab",
    label: "cab",
  },
  {
    value: ".cafe",
    label: "cafe",
  },
  {
    value: ".care",
    label: "care",
  },
  {
    value: ".cash",
    label: "cash",
  },
  {
    value: ".cc",
    label: "cc",
  },
  {
    value: ".center",
    label: "center",
  },
  {
    value: ".chat",
    label: "chat",
  },
  {
    value: ".city",
    label: "city",
  },
  {
    value: ".click",
    label: "click",
  },
  {
    value: ".club",
    label: "club",
  },
  {
    value: ".cn",
    label: "cn",
  },
  {
    value: ".co",
    label: "co",
  },
  {
    value: ".coffee",
    label: "coffee",
  },
  {
    value: ".college",
    label: "college",
  },
  {
    value: ".com",
    label: "com",
  },
  {
    value: ".community",
    label: "community",
  },
  {
    value: ".company",
    label: "company",
  },
  {
    value: ".contact",
    label: "contact",
  },
  {
    value: ".cool",
    label: "cool",
  },
  {
    value: ".cyou",
    label: "cyou",
  },
  {
    value: ".day",
    label: "day",
  },
  {
    value: ".design",
    label: "design",
  },
  {
    value: ".dev",
    label: "dev",
  },
  {
    value: ".digital",
    label: "digital",
  },
  {
    value: ".dog",
    label: "dog",
  },
  {
    value: ".email",
    label: "email",
  },
  {
    value: ".engineer",
    label: "engineer",
  },
  {
    value: ".events",
    label: "events",
  },
  {
    value: ".family",
    label: "family",
  },
  {
    value: ".fan",
    label: "fan",
  },
  {
    value: ".fans",
    label: "fans",
  },
  {
    value: ".fish",
    label: "fish",
  },
  {
    value: ".fit",
    label: "fit",
  },
  {
    value: ".fun",
    label: "fun",
  },
  {
    value: ".fund",
    label: "fund",
  },
  {
    value: ".fyi",
    label: "fyi",
  },
  {
    value: ".games",
    label: "games",
  },
  {
    value: ".global",
    label: "global",
  },
  {
    value: ".gold",
    label: "gold",
  },
  {
    value: ".golf",
    label: "golf",
  },
  {
    value: ".green",
    label: "green",
  },
  {
    value: ".group",
    label: "group",
  },
  {
    value: ".guide",
    label: "guide",
  },
  {
    value: ".guru",
    label: "guru",
  },
  {
    value: ".horse",
    label: "horse",
  },
  {
    value: ".icu",
    label: "icu",
  },
  {
    value: ".inc",
    label: "inc",
  },
  {
    value: ".info",
    label: "info",
  },
  {
    value: ".ink",
    label: "ink",
  },
  {
    value: ".jobs",
    label: "jobs",
  },
  {
    value: ".kiwi",
    label: "kiwi",
  },
  {
    value: ".land",
    label: "land",
  },
  {
    value: ".law",
    label: "law",
  },
  {
    value: ".life",
    label: "life",
  },
  {
    value: ".link",
    label: "link",
  },
  {
    value: ".live",
    label: "live",
  },
  {
    value: ".love",
    label: "love",
  },
  {
    value: ".ltd",
    label: "ltd",
  },
  {
    value: ".mba",
    label: "mba",
  },
  {
    value: ".media",
    label: "media",
  },
  {
    value: ".men",
    label: "men",
  },
  {
    value: ".museum",
    label: "museum",
  },
  {
    value: ".net",
    label: "net",
  },
  {
    value: ".network",
    label: "network",
  },
  {
    value: ".news",
    label: "news",
  },
  {
    value: ".one",
    label: "one",
  },
  {
    value: ".ong",
    label: "ong",
  },
  {
    value: ".onl",
    label: "onl",
  },
  {
    value: ".org",
    label: "org",
  },
  {
    value: ".page",
    label: "page",
  },
  {
    value: ".pet",
    label: "pet",
  },
  {
    value: ".photo",
    label: "photo",
  },
  {
    value: ".photos",
    label: "photos",
  },
  {
    value: ".pictures",
    label: "pictures",
  },
  {
    value: ".pink",
    label: "pink",
  },
  {
    value: ".place",
    label: "place",
  },
  {
    value: ".plus",
    label: "plus",
  },
  {
    value: ".pro",
    label: "pro",
  },
  {
    value: ".promo",
    label: "promo",
  },
  {
    value: ".pub",
    label: "pub",
  },
  {
    value: ".red",
    label: "red",
  },
  {
    value: ".ren",
    label: "ren",
  },
  {
    value: ".rent",
    label: "rent",
  },
  {
    value: ".report",
    label: "report",
  },
  {
    value: ".rest",
    label: "rest",
  },
  {
    value: ".rip",
    label: "rip",
  },
  {
    value: ".rocks",
    label: "rocks",
  },
  {
    value: ".run",
    label: "run",
  },
  {
    value: ".sale",
    label: "sale",
  },
  {
    value: ".school",
    label: "school",
  },
  {
    value: ".security",
    label: "security",
  },
  {
    value: ".show",
    label: "show",
  },
  {
    value: ".site",
    label: "site",
  },
  {
    value: ".ski",
    label: "ski",
  },
  {
    value: ".social",
    label: "social",
  },
  {
    value: ".software",
    label: "software",
  },
  {
    value: ".solutions",
    label: "solutions",
  },
  {
    value: ".studio",
    label: "studio",
  },
  {
    value: ".style",
    label: "style",
  },
  {
    value: ".support",
    label: "support",
  },
  {
    value: ".surf",
    label: "surf",
  },
  {
    value: ".tax",
    label: "tax",
  },
  {
    value: ".team",
    label: "team",
  },
  {
    value: ".tech",
    label: "tech",
  },
  {
    value: ".tel",
    label: "tel",
  },
  {
    value: ".theatre",
    label: "theatre",
  },
  {
    value: ".tips",
    label: "tips",
  },
  {
    value: ".today",
    label: "today",
  },
  {
    value: ".top",
    label: "top",
  },
  {
    value: ".toys",
    label: "toys",
  },
  {
    value: ".travel",
    label: "travel",
  },
  {
    value: ".us",
    label: "us",
  },
  {
    value: ".vegas",
    label: "vegas",
  },
  {
    value: ".vet",
    label: "vet",
  },
  {
    value: ".video",
    label: "video",
  },
  {
    value: ".vin",
    label: "vin",
  },
  {
    value: ".vip",
    label: "vip",
  },
  {
    value: ".vote",
    label: "vote",
  },
  {
    value: ".wang",
    label: "wang",
  },
  {
    value: ".website",
    label: "website",
  },
  {
    value: ".wiki",
    label: "wiki",
  },
  {
    value: ".win",
    label: "win",
  },
  {
    value: ".work",
    label: "work",
  },
  {
    value: ".works",
    label: "works",
  },
  {
    value: ".world",
    label: "world",
  },
  {
    value: ".wtf",
    label: "wtf",
  },
  {
    value: ".xin",
    label: "xin",
  },
  {
    value: ".xyz",
    label: "xyz",
  },
  {
    value: ".yoga",
    label: "yoga",
  },
  {
    value: ".zone",
    label: "zone",
  },
];

const availSuffix = [
  {
    value: 'academy',
  },
  {
    value: 'agency',
  },
  {
    value: 'app',
  },
  {
    value: 'archi',
  },
  {
    value: 'art',
  },
  {
    value: 'asia',
  },
  {
    value: 'audio',
  },
  {
    value: 'auto',
  },
  {
    value: 'baby',
  },
  {
    value: 'band',
  },
  {
    value: 'bar',
  },
  {
    value: 'beer',
  },
  {
    value: 'best',
  },
  {
    value: 'bet',
  },
  {
    value: 'bid',
  },
  {
    value: 'bio',
  },
  {
    value: 'biz',
  },
  {
    value: 'black',
  },
  {
    value: 'blog',
  },
  {
    value: 'blue',
  },
  {
    value: 'bond',
  },
  {
    value: 'boo',
  },
  {
    value: 'build',
  },
  {
    value: 'business',
  },
  {
    value: 'cab',
  },
  {
    value: 'cafe',
  },
  {
    value: 'car',
  },
  {
    value: 'care',
  },
  {
    value: 'cash',
  },
  {
    value: 'cc',
  },
  {
    value: 'center',
  },
  {
    value: 'ceo',
  },
  {
    value: 'cfd',
  },
  {
    value: 'chat',
  },
  {
    value: 'city',
  },
  {
    value: 'click',
  },
  {
    value: 'cloud',
  },
  {
    value: 'club',
  },
  {
    value: 'codes',
  },
  {
    value: 'coffee',
  },
  {
    value: 'college',
  },
  {
    value: 'com',
  },
  {
    value: 'community',
  },
  {
    value: 'company',
  },
  {
    value: 'contact',
  },
  {
    value: 'cool',
  },
  {
    value: 'cyou',
  },
  {
    value: 'date',
  },
  {
    value: 'day',
  },
  {
    value: 'design',
  },
  {
    value: 'dev',
  },
  {
    value: 'digital',
  },
  {
    value: 'dog',
  },
  {
    value: 'earth',
  },
  {
    value: 'eco',
  },
  {
    value: 'email',
  },
  {
    value: 'engineer',
  },
  {
    value: 'events',
  },
  {
    value: 'family',
  },
  {
    value: 'fan',
  },
  {
    value: 'fans',
  },
  {
    value: 'fish',
  },
  {
    value: 'fit',
  },
  {
    value: 'fr',
  },
  {
    value: 'fun',
  },
  {
    value: 'fund',
  },
  {
    value: 'fyi',
  },
  {
    value: 'games',
  },
  {
    value: 'global',
  },
  {
    value: 'gold',
  },
  {
    value: 'golf',
  },
  {
    value: 'green',
  },
  {
    value: 'group',
  },
  {
    value: 'guide',
  },
  {
    value: 'guru',
  },
  {
    value: 'help',
  },
  {
    value: 'homes',
  },
  {
    value: 'horse',
  },
  {
    value: 'host',
  },
  {
    value: 'how',
  },
  {
    value: 'icu',
  },
  {
    value: 'inc',
  },
  {
    value: 'info',
  },
  {
    value: 'ink',
  },
  {
    value: 'jobs',
  },
  {
    value: 'kiwi',
  },
  {
    value: 'land',
  },
  {
    value: 'law',
  },
  {
    value: 'life',
  },
  {
    value: 'link',
  },
  {
    value: 'live',
  },
  {
    value: 'llc',
  },
  {
    value: 'lol',
  },
  {
    value: 'lotto',
  },
  {
    value: 'love',
  },
  {
    value: 'ltd',
  },
  {
    value: 'market',
  },
  {
    value: 'mba',
  },
  {
    value: 'media',
  },
  {
    value: 'men',
  },
  {
    value: 'mobi',
  },
  {
    value: 'museum',
  },
  {
    value: 'name',
  },
  {
    value: 'net',
  },
  {
    value: 'network',
  },
  {
    value: 'news',
  },
  {
    value: 'one',
  },
  {
    value: 'ong',
  },
  {
    value: 'onl',
  },
  {
    value: 'online',
  },
  {
    value: 'org',
  },
  {
    value: 'page',
  },
  {
    value: 'party',
  },
  {
    value: 'pet',
  },
  {
    value: 'photo',
  },
  {
    value: 'photos',
  },
  {
    value: 'pics',
  },
  {
    value: 'pictures',
  },
  {
    value: 'pink',
  },
  {
    value: 'place',
  },
  {
    value: 'plus',
  },
  {
    value: 'press',
  },
  {
    value: 'pro',
  },
  {
    value: 'promo',
  },
  {
    value: 'pub',
  },
  {
    value: 'red',
  },
  {
    value: 'ren',
  },
  {
    value: 'rent',
  },
  {
    value: 'report',
  },
  {
    value: 'rest',
  },
  {
    value: 'rip',
  },
  {
    value: 'rocks',
  },
  {
    value: 'run',
  },
  {
    value: 'sale',
  },
  {
    value: 'school',
  },
  {
    value: 'security',
  },
  {
    value: 'shop',
  },
  {
    value: 'show',
  },
  {
    value: 'site',
  },
  {
    value: 'ski',
  },
  {
    value: 'social',
  },
  {
    value: 'software',
  },
  {
    value: 'solutions',
  },
  {
    value: 'space',
  },
  {
    value: 'store',
  },
  {
    value: 'studio',
  },
  {
    value: 'style',
  },
  {
    value: 'support',
  },
  {
    value: 'surf',
  },
  {
    value: 'tax',
  },
  {
    value: 'team',
  },
  {
    value: 'tech',
  },
  {
    value: 'tel',
  },
  {
    value: 'theatre',
  },
  {
    value: 'tips',
  },
  {
    value: 'today',
  },
  {
    value: 'top',
  },
  {
    value: 'toys',
  },
  {
    value: 'trade',
  },
  {
    value: 'travel',
  },
  {
    value: 'unicom',
  },
  {
    value: 'vegas',
  },
  {
    value: 'vet',
  },
  {
    value: 'video',
  },
  {
    value: 'vin',
  },
  {
    value: 'vip',
  },
  {
    value: 'vote',
  },
  {
    value: 'wang',
  },
  {
    value: 'website',
  },
  {
    value: 'wiki',
  },
  {
    value: 'win',
  },
  {
    value: 'work',
  },
  {
    value: 'works',
  },
  {
    value: 'world',
  },
  {
    value: 'wtf',
  },
  {
    value: 'xin',
  },
  {
    value: 'xxx',
  },
  {
    value: 'xyz',
  },
  {
    value: 'yoga',
  },
  {
    value: 'zone',
  },
];